import React, { useState, useEffect } from "react"
import Layout from "../../Components/pageComponents/contentContainer"
// import Hero from "../../Components/ssp/hero"
import { Container, Collapse, Button } from "reactstrap"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EngineerService from "../../Components/pageComponents/engineerService"
// import directory from "../../content/directory.json"
import style from "./styles.module.css"
export default function EngineerProfessionalServices() {
  const subInfo = [
    {
      items: ["Consultation and Assessment", "Cable Certification Testing", "Design and Implementation"]
    },
    {
      items: ["Network Assessment and Optimization", "Wired / Wireless Expansion System"]

    },
    {
      items: ["Provisioning and Back-Up", "Archival Operations"]
    },
    {
      items: ["Back-up power supply system (UPS)", "Ventilation & cooling system (HVAC)", "Redundant networking connections ", "Fire suppression", "Security system"]
    },
    {
      items: ["Reactive", "Preventive", "Predictive"]
    },

  ]
  const items = [
    {
      name: "Infrastructure"
    },
    {
      name: "Networking"
    },
    {
      name: "Storage"
    },
    {
      name: "Mechanical and Electrical"
    },
    {
      name: "Maintenance"
    }
  ]

  const Image = useStaticQuery(graphql`
    query {
      circle: file(relativePath: { eq: "services/engineer/circle.png" }) {
        childImageSharp {
          fluid (maxWidth:1000){
            ...GatsbyImageSharpFluid
          }
        }
      }
      arrow: file(relativePath: { eq: "services/engineer/Engineering Professional Services Arrow Table.png" }) {
        childImageSharp {
          fluid (maxWidth:1000){
            ...GatsbyImageSharpFluid
          }
        }
      }
      maintenance: file(relativePath: { eq: "services/engineer/Maintenance Pub.png" }) {
        childImageSharp {
          fluid (maxWidth:1000){
            ...GatsbyImageSharpFluid
          }
        }
      }
      prosAndCons: file(relativePath: { eq: "services/engineer/Pros ad Cons Table.png" }) {
        childImageSharp {
          fluid (maxWidth:1000){
            ...GatsbyImageSharpFluid
          }
        }
      }
      banner: file(relativePath: { eq: "services/engineer/Engineering Professional Services.png" }) {
        childImageSharp {
          fluid (maxWidth:1000){
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [isOpen, setIsOpen] = useState(false);
  const [anchorTarget, setAnchorTarget] = useState(null)

  useEffect(() => {
    setAnchorTarget(document.getElementById("maintenance"))
  }, [])

  const handleClick = event => {
    event.preventDefault()
    anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  const toggle = () => setIsOpen(!isOpen);
  return (
    <Layout image={Image.banner.childImageSharp.fluid} title="Engineering Professional Services ">
      <Container
        fluid
        className="pt-5 pb-5"
        style={{ backgroundColor: `#F3F3F3` }}
      >
        <h1 className="mx-auto mb-3 heading1 pt-3 text-center">
          <span>Services we offer</span>
        </h1>


        <div className={style.imgContainer}>
          <div style={{
            width: `40%`,
            maxWidth: `400px`,
            minWidth: `300px`,
            display: `block`,
            marginLeft: `auto`,
            marginRight: `auto`
          }}>
            <Img fluid={Image.circle.childImageSharp.fluid} />
          </div>
          <Button onClick={toggle} className={style.button1}>See More</Button>
        </div>

        <Collapse isOpen={isOpen} className={style.collapse}>
          <Img fluid={Image.arrow.childImageSharp.fluid} />
        </Collapse>
        <EngineerService info={items} subInfo={subInfo} handleClick={handleClick} />



      </Container>

      <Container
        id="maintenance"
        fluid
        className="pt-5 pb-5"
        style={{ backgroundColor: `var(--FSC-YELLOW)` }}

      >
        <h1 className="mx-auto mb-4 heading1 p-3 text-center">
          <span>Maintenance Service</span>
        </h1>
        <div style={{
          width: `80%`,
          maxWidth: `1000px`,
          minWidth: `300px`,
          display: `block`,
          marginLeft: `auto`,
          marginRight: `auto`
        }}>
          <Img fluid={Image.maintenance.childImageSharp.fluid} />
          <Img fluid={Image.prosAndCons.childImageSharp.fluid} />
        </div>

      </Container>
    </Layout>
  )
}
