import React from "react"
// import Img from "gatsby-image"
import { Button } from "reactstrap"
import style from "./styles.module.css"

const EngineerService = ({ info, subInfo, handleClick }) => {
  // console.log(subInfo)
  // const subItems = subInfo.map(({items})=>{
  //   const subItem = items.map(({name})=>{
  //     return (
  //       <li>
  //         {name}
  //       </li>
  //       )
  //   })
  //   return(
  //     <>{subItem}</>
  //     )
  // })

  const items = info.map(({ name }, index) => {
    const subItems = subInfo[index].items.map(name => {
      return <li>{name}</li>
    })
    if (name === "Maintenance") {
      return (
        <div className={style.engineerCard} key={index}>
          <div className={style.engineerContent}>
            <h4>{name}</h4>
            <ul>{subItems}</ul>
          </div>
          <div className={style.contentButton}>
            <Button onClick={handleClick}>Learn More</Button>
          </div>
        </div>
      )
    }
    return (
      <div className={style.engineerCard} key={index}>
        <div className={style.engineerContent}>
          <h4>{name}</h4>
          <ul>{subItems}</ul>
        </div>
      </div>
    )
  })
  return <div className={style.itemContainer}>{items}</div>
}

export default EngineerService
